<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { ProductCardHorizontalProps } from './UIProductCardHorizontal.props'
import type { ProductGalleryImage } from './UIProductGallery.props'

const props = withDefaults(defineProps<ProductCardHorizontalProps>(), {
  disableGallery: false,
  enableQuickBuy: false,
  isPriceVisible: true,
  showActionsByDefault: false,
})

const emit = defineEmits<{
  openQuickBuyPopUpLayer: [product: ProductCardHorizontalProps]
  onIncreaseQty: [id: string]
  onDecreaseQty: [id: string]
}>()

const { openDialog } = useDialog()
const { formatPriceByLocale } = useFormatPrice()
const { currentCurrency } = useCurrency()

const product = getSkuWithoutSize(props.skuCode) ?? ''
const productGalleryId = `gallery-pop-up-layer-${props.skuCode}`

const isQuantityUpdating = computed(
  () => props.itemsQtyUpdating?.[props.id] ?? false
)

const handleImageClick = () => {
  openDialog(productGalleryId)
}

const handleSelectItem = () => {
  emit('openQuickBuyPopUpLayer', props)
  dispatchSelectItemEvent({
    sku: props.skuCode.split('_').slice(0, 3).join('_'),
    name: props.info.name,
    brand: props.metadata,
    color: props.info.color as string,
    price: props.info.price,
    quantity: props.quantity ?? 1,
    available: props.available,
    img: props.image,
    discount:
      props.info.oldPrice && props.info.oldPrice !== props.info.price
        ? props.info.oldPrice - props.info.price
        : 0,
  })
}

const { ts } = useI18n()
const quickBuyAriaLabel = `${ts('aria-labels.cartPage.openQuickbuy')} ${props.info.name}`

const { data: orderedGallery } = await useOrderedGallery(product)

// Filter TResponsiveImage for full screen gallery
const gallery = computed(() =>
  (orderedGallery?.value ?? []).filter(
    (item): item is ProductGalleryImage =>
      item.type === 'TResponsiveImage' || item.type === 'TResponsiveVideo'
  )
)

const notReturnableReason = computed(() => {
  switch (true) {
    case props.notReturnableNotShipped:
      return ts('order.return.notReturnableNotShipped')
    case props.notReturnableReturnAlreadyStarted:
      return ts('order.return.notReturnableReturnAlreadyStarted')
    case props.notReturnableFinalSale:
      return ts('order.return.notReturnableFinalSale')
    case props.notReturnableReturnPeriodExpired:
      return ts('order.return.notReturnableReturnPeriodExpired')
    case props.otherNotReturnableReason:
      return ts('order.return.otherNotReturnableReason')
    default:
      return false
  }
})

const { dispatchSelectItemEvent } = useGASelectItem()
const isAvailable = computed(
  () =>
    !!(
      props.available &&
      props.quantity &&
      props.stockQty &&
      props.quantity < props.stockQty
    )
)

const isLastItem = computed(() =>
  props.stockQty && props.quantity
    ? props.stockQty - props.quantity === 0
    : false
)

const pdpLink = computed(() => ({
  tag: resolveComponent('NuxtLink'),
  props: {
    to: props.path,
    title: props.info.name,
  },
  events: {},
}))

const imageWrapper = computed(() =>
  props.disableGallery
    ? pdpLink.value
    : {
        tag: 'button',
        props: {
          'aria-haspopup': 'dialog',
          'aria-label': `${ts('accessibility.openGalleryImage', { label: props.info.name })}`,
          disabled: !props.available,
        },
        events: {
          click: handleImageClick,
        },
      }
)

const titleWrapper = computed(() =>
  !props.enableQuickBuy || !props.available
    ? pdpLink.value
    : {
        tag: 'button',
        props: {
          'aria-label': quickBuyAriaLabel,
          'aria-haspopup': 'dialog',
        },
        events: {
          click: handleSelectItem,
        },
      }
)
</script>

<template>
  
<FormKitLazyProvider config-file="true">
<UIProductGallery
    v-if="!disableGallery"
    :id="productGalleryId"
    :gallery="gallery"
    :title="info.name"
  />
  <div
    :id="id"
    class="product-card-horizontal__wrapper flex gap-x-4"
    data-testid="preview-checkout"
    :class="{
      'flex-wrap': isCart || isCheckout || showActionsByDefault,
      'w-full': isReturn,
      'outline-primitives-red outline outline-2 outline-offset-8':
        showErrorBorder && (!available || !isAvailable),
    }"
  >
    <div
      class="w-30 h-37 flex-shrink-0"
      :class="{
        'border-primitives-grey-100 border': isCheckout,
        'opacity-[0.4]': !available,
      }"
    >
      <component
        :is="imageWrapper.tag"
        :="imageWrapper.props"
        class="w-full"
        :class="{ 'custom-zoom-cursor-add-small': !disableGallery }"
        aria-hidden="true"
        tabindex="-1"
        v-on="imageWrapper.events"
      >
        <NuxtPicture
          v-if="image"
          :src="image"
          :sku-product="skuCode"
          provider="cloudinary"
          width="676"
          height="845"
          :placeholder="[67, 85]"
          :alt="info.name"
          class="aspect-pdp-image block"
          :modifiers="{ aspectRatio: '4:5' }"
          fit="fill"
          sizes="lg:10vw sm:20vw"
          :class="{
            'border-primitives-grey-100 border':
              (isCart || hasImageBorder) && available,
            'opacity-40': snackbarActive && available,
          }"
          :img-attrs="{ class: 'h-auto w-full}' }"
        />
      </component>
    </div>
    <div
      v-if="info"
      class="flex flex-col"
      :class="{
        'w-[calc(100%-8.5rem)]':
          isCart || isCheckout || isOrder || showActionsByDefault,
        'opacity-40': !available || snackbarActive,
        'w-full': isThankYou! || isReturn,
      }"
    >
      <component
        :is="titleWrapper.tag"
        :="titleWrapper.props"
        v-on="titleWrapper.events"
      >
        <component
          :is="available ? 'span' : 'h3'"
          class="text-book-6 mb-1 line-clamp-2 text-left uppercase"
          :class="{
            '!line-clamp-1 text-ellipsis':
              isCart || isCheckout || showActionsByDefault,
          }"
        >
          {{ info.name }}
        </component>
      </component>
      <div class="text-light-6 mb-4 flex gap-x-2">
        <s
          v-if="info.oldPrice && info.oldPrice !== info.price"
          class="text-text-tertiary"
        >
          {{ formatPriceByLocale(currentCurrency, info.oldPrice) }}
        </s>
        <span>{{ formatPriceByLocale(currentCurrency, info.price) }}</span>
        <span
          v-if="info.oldPrice && info.oldPrice !== info.price"
          class="text-medium-6"
          >{{ percentageDiscount(info.oldPrice, info.price) }}</span
        >
      </div>

      <span class="text-light-6 mb-xxs">
        <span class="text-medium-6">
          {{ $ts('productCard.info.color') }}
        </span>
        {{ info.color }}</span
      >
      <span v-if="!!info.size" class="text-light-6">
        <span class="text-medium-6">
          {{ $ts('productCard.info.size') }}
        </span>
        {{ info.size }}
      </span>

      <div v-if="notReturnableReason" class="mt-4">
        <AtomsBadgeProductCart :text="notReturnableReason" />
      </div>
      <div v-show="isOneExtSize" class="mt-4">
        <AtomsBadgeProductCart :text="$ts('pdp.sizeSelector.oneSize')" />
      </div>

      <div v-if="isReturn && returnable" class="flex justify-between">
        <div class="flex gap-x-1">
          <span class="text-medium-6">
            {{ $ts('productCard.info.quantity') }}
          </span>
          <span class="text-light-6">
            {{ quantity }}
            <FormKit
              :value="quantity"
              type="hidden"
              number="integer"
              name="return-item-quantity"
              outer-class="hidden"
            />
          </span>
        </div>
        <p v-if="itemTotalPrice" class="text-medium-6">
          {{ formatPriceByLocale(currentCurrency, itemTotalPrice) }}
        </p>
      </div>

      <div v-if="isCart" class="flex items-center justify-between pt-4">
        <span>
          <UIQuantityPicker
            :available-product-stock="available ?? true"
            :disable-add="!isAvailable"
            :disable-decrease="quantity === 1"
            :loading="isQuantityUpdating"
            @increase="emit('onIncreaseQty', id)"
            @decrease="emit('onDecreaseQty', id)"
          >
            {{ quantity }}
          </UIQuantityPicker>
        </span>
        <div v-if="isPriceVisible" class="flex justify-end">
          <p v-if="itemTotalPrice" class="text-medium-6">
            {{ formatPriceByLocale(currentCurrency, itemTotalPrice) }}
          </p>
        </div>
      </div>

      <div
        v-else-if="isCheckout || isOrder || isThankYou"
        class="flex items-center justify-between"
      >
        <span class="text-light-6">
          <span class="text-medium-6">
            {{ $ts('productCard.info.quantity') }}
          </span>
          {{ quantity }}
        </span>
        <span v-if="itemTotalPrice" class="text-medium-6">{{
          formatPriceByLocale(currentCurrency, itemTotalPrice)
        }}</span>
      </div>

      <AtomsMessageShort
        v-if="isOrder && returnRequested"
        class="mt-4 print:hidden"
        icon="return"
        :text="ts('productCard.returnRequested')"
      />
    </div>

    <div
      v-if="isCart || isCheckout || showActionsByDefault"
      class="flex w-full flex-col items-end justify-center gap-[10px] md:flex-row md:items-center md:justify-between"
      :class="{ 'mt-4': !available || isLastItem }"
    >
      <div
        v-if="!available && !snackbarActive"
        class="px-sm py-xxs bg-primitives-grey-50 whitespace-nowrap"
      >
        <span class="text-book-7">
          {{ $ts('productCard.notAvailable') }}
        </span>
      </div>
      <div
        v-if="isLastItem && !snackbarActive"
        class="px-sm py-xxs bg-primitives-grey-50 whitespace-nowrap"
      >
        <span class="text-book-7"> {{ ts('productCard.lastItem') }} </span>
      </div>
      <div v-if="$slots.actions" class="mt-sm flex w-full flex-col items-end">
        <slot name="actions" />
      </div>
    </div>
  </div>
  <div
    v-if="!isTrackReturn"
    class="border-primitives-grey-100 my-6 h-[1px] border-b last:hidden"
  />
</FormKitLazyProvider>

</template>
<style scoped lang="scss">
.custom-zoom-cursor-add-small {
  cursor:
    url('@design-system/icons/AddWBkgSmall.svg') 12 12,
    auto !important;
}
</style>
