import type { ProductSchema } from '#build/armani/product-schema'

export const getRawImages = (assets: ProductSchema['assets']) => {
  const images: TResponsiveImage[] = []

  if (assets === undefined) return images

  assets.forEach(image => {
    if (image.type === 'jpg' && typeof image.URL === 'string') {
      images.push(image.URL)
    }
  })

  // order the images by priority
  images.sort((a, b) => {
    const priorityA = assets.find(image => image.URL === a)?.priority
    const priorityB = assets.find(image => image.URL === b)?.priority

    if (typeof priorityA === 'number' && typeof priorityB === 'number') {
      return priorityA - priorityB
    }

    return 0
  })

  return images
}

export const getFormattedImages = (assets: ProductSchema['assets']) => {
  return getRawImages(assets).map(image =>
    image.split('/').slice(-1).toString()
  )
}
export const get3DModelURLs = (assets: ProductSchema['assets']) => {
  const modelURL = assets?.find(i => i.type === 'glb')?.URL
  return modelURL ? [modelURL] : []
}

export const getFormattedVideos = (assets: ProductSchema['assets']) => {
  const videos: TResponsiveVideo[] = []

  if (assets === undefined) return videos

  assets.forEach(video => {
    if (video.type === 'mov' && typeof video.URL === 'string') {
      const base = video.URL.substring(0, video.URL.lastIndexOf('.'))
      const cover = `${base}.jpg`
      videos.push({ url: video.URL, cover })
    }
  })

  return videos
}

export const getFormattedImage3d = (assets: ProductSchema['assets']) => {
  const url = assets?.find(asset => asset.type === 'glb')?.URL
  if (url) {
    // this is quick and dirty but we don't have time to make a better regex
    const isStagingUrl = url.includes('cloudinary')
    const regex = isStagingUrl
      ? /^.*cloudinary.com\/(?<cloud>.*).image\/.*upload\/(?<model>.*).glb/
      : /^.*\/(?<model>.*).glb/

    const matchModel = url.match(regex)
    if (matchModel)
      return {
        cloud: isStagingUrl ? matchModel[1] : '',
        model: isStagingUrl ? matchModel[2] : matchModel[1],
      }
  }

  return undefined
}

// if there is a Video, it should be the second element of the gallery
// if there is a Video and a 3D image, the 3D image should NOT be visible
// if there are multiples videos, the first one should be visible on the second position, the rest should be visible from the fourth position
export const getOrderedGallery = (assets: ProductSchema['assets']) => {
  const images = getFormattedImages(assets)
  const videoGallery = getFormattedVideos(assets)
  const image3dViz = getFormattedImage3d(assets)

  const gallery = images.map(image => {
    return {
      type: 'TResponsiveImage' as const,
      data: image,
    }
  })

  const video =
    videoGallery &&
    videoGallery.map(video => {
      return {
        type: 'TResponsiveVideo' as const,
        data: video,
      }
    })

  const viz3D = image3dViz && {
    type: 'TResponsiveImage3D' as const,
    data: image3dViz,
  }

  if (!gallery) {
    return []
  }

  if (video?.length) {
    if (video.length === 1) {
      return [gallery[0], video[0], ...gallery.slice(1)]
    } else {
      if (gallery.length === 1) {
        return [gallery[0], video[0], ...video.slice(1)]
      }

      return [
        gallery[0],
        video[0],
        gallery[1],
        ...gallery.slice(2),
        ...video.slice(1),
      ]
    }
  }

  if (viz3D) {
    return [gallery[0], viz3D, ...gallery.slice(1)]
  }

  return gallery
}
